import { Injectable } from '@angular/core';
import { bindCallback, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiVcpAssetService } from '../core/services/api/vsblty-customer-portal/api.vcp.asset.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(protected _apiVcpAssetService: ApiVcpAssetService) { }

  // this are set in the root app component, using host
  public globalListeners: GlobalListeners = new GlobalListeners();

  public convertImgToBase64(url: string): Observable<string> {
    let convertImgToBase64 = bindCallback(this._convertImgToBase64);
    let result = convertImgToBase64(url);
    return result
      .pipe(
        map(
          resp => {
            return resp as unknown as string;
          }
        )
      );
  }

  private _convertImgToBase64(url: string, callback) {
    let canvas: HTMLCanvasElement = document.createElement('CANVAS') as HTMLCanvasElement;
    let ctx = canvas.getContext('2d');
    let img = new Image;
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      let dataURL = canvas.toDataURL('image/png');
      callback(dataURL);
      // Clean up
      canvas = null;
    };
    img.src = url;
  }

  private _rem() {
    var html = document.getElementsByTagName('html')[0];
    return parseInt(window.getComputedStyle(html)['fontSize']);
  }
  // This function will convert pixel to rem
  pixelsToRem(pixels: number): number {
    return (pixels / this._rem());
  }
  remToPixels(rem: number): number {
    return this._rem() * rem;
  }

  isCharacterALetter(char: string) {
    if (char == undefined || char.length != 1) return false;
    return (/[a-zA-Z]/).test(char);
  }

  dataToString(value: Array<any>): string;
  dataToString(value: string): string;
  dataToString(value: number): string;
  dataToString(value: boolean): string;
  dataToString(value: object): string;
  dataToString(value: Array<any> | string | number | boolean | object): string {
    if (value == undefined) return '';
    // since stringify doesn't guarantee the properties will be in order all the time
    // we have to do it ourselves
    // 1: We turn it into a string, to get rid of all the properties that should not
    // be taken into consideration
    let jsonString = JSON.stringify(value);
    // 2: We turn the string back to an object so then we can make transform it into
    // a string but with the properties in the correct order.
    let jsonObj = JSON.parse(jsonString);

    return this._dataToString(jsonObj);
  }

  private _dataToString(value: Array<any> | string | number | boolean | object): string {
    if (value == undefined) return '';
    if (typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean') return `"${value}"`;

    let isArray: boolean = (<Array<any>>value).length != undefined;
    let result = isArray ? '[' : '{';
    let keys = Object.keys(value).sort();
    keys.forEach((key, it) => {
      let item = value[key];
      let type = typeof item;
      if (it > 0) {
        result += ',';
      }

      if (type === 'string' ||
        type === 'number' ||
        type === 'boolean') {

        if (isArray) {
          result += type === 'string' ? `"${item}"` : `${item}`;
        } else {
          result += type === 'string' ? `"${key}": "${item}"` : `"${key}": ${item}`;
        }
      }
      // if it is an array or object
      else {
        if (isArray) {
          result += this.dataToString(item);
        } else {
          result += `"${key}": ${this.dataToString(item) || null}`;
        }
      }
    });
    result += isArray ? ']' : '}';

    return result;
  }
}

class GlobalListeners {
  public click: Click = {
    any: new Subject<MouseEvent>(),
    left: new Subject<MouseEvent>(),
    right: new Subject<MouseEvent>(),
    middle: new Subject<MouseEvent>(),
  }
  public key: IKey = new Key();
  public window: IWindow = new Window()
}

interface IWindow {
  resize: Subject<Event>;
}

class Window implements IWindow {
  resize: Subject<Event> = new Subject<Event>();
}

interface Click {
  any: Subject<MouseEvent>;
  left: Subject<MouseEvent>;
  middle: Subject<MouseEvent>;
  right: Subject<MouseEvent>;
}

class Key implements IKey {
  a: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  b: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  c: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  d: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  e: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  f: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  g: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  h: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  i: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  j: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  k: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  l: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  m: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  n: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  o: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  p: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  q: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  r: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  s: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  t: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  u: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  v: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  w: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  x: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  y: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  z: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  // up: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  // down: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  // left: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  // right: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  ctrl: LetterOrArrow = new LetterOrArrow();
  alt: LetterOrArrow = new LetterOrArrow();
  shift: LetterOrArrow = new LetterOrArrow();
  tab: LetterOrArrow = new LetterOrArrow();
  ctrlShift: LetterOrArrow = new LetterOrArrow();
  altShift: LetterOrArrow = new LetterOrArrow();
  ctrlAlt: LetterOrArrow = new LetterOrArrow();
  ctrlShiftAlt: LetterOrArrow = new LetterOrArrow();
  arrow: Arrow = new Arrow();
  numpad: Numpad = new Numpad();
  delete: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  insert: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  escape: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  backspace: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
}

class LetterOrArrow implements ILetterOrArrow {
  a: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  b: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  c: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  d: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  e: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  f: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  g: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  h: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  i: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  j: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  k: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  l: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  m: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  n: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  o: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  p: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  q: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  r: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  s: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  t: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  u: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  v: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  w: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  x: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  y: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  z: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  arrow: Arrow = new Arrow();
  numpad: Numpad = new Numpad();
  delete: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  insert: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  escape: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  backspace: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
}

class Letter implements ILetter {
  a: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  b: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  c: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  d: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  e: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  f: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  g: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  h: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  i: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  j: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  k: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  l: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  m: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  n: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  o: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  p: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  q: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  r: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  s: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  t: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  u: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  v: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  w: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  x: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  y: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  z: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
}

class Arrow implements IArrow {
  up: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  down: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  left: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  right: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
}

class Numpad implements INumpad {
  1: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  2: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  3: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  4: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  5: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  6: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  7: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  8: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  9: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  0: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  asterisk: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();;
  forwardSlash: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();;
  dash: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();;
  plus: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();;
  dot: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();;
}
interface IKey extends ILetterOrArrow {
  ctrl: ILetterOrArrow;
  alt: ILetterOrArrow;
  shift: ILetterOrArrow;
  ctrlShift: ILetterOrArrow;
  altShift: ILetterOrArrow;
  ctrlAlt: ILetterOrArrow;
  ctrlShiftAlt: ILetterOrArrow;
  tab: ILetterOrArrow;
  arrow: IArrow;
}
interface ILetterOrArrow extends ILetter {
  arrow: IArrow;
  numpad: INumpad;
  delete: Subject<KeyboardEvent>;
  insert: Subject<KeyboardEvent>;
  escape: Subject<KeyboardEvent>;
  backspace: Subject<KeyboardEvent>;
}
interface ILetter {
  a: Subject<KeyboardEvent>;
  b: Subject<KeyboardEvent>;
  c: Subject<KeyboardEvent>;
  d: Subject<KeyboardEvent>;
  e: Subject<KeyboardEvent>;
  f: Subject<KeyboardEvent>;
  g: Subject<KeyboardEvent>;
  h: Subject<KeyboardEvent>;
  i: Subject<KeyboardEvent>;
  j: Subject<KeyboardEvent>;
  k: Subject<KeyboardEvent>;
  l: Subject<KeyboardEvent>;
  m: Subject<KeyboardEvent>;
  n: Subject<KeyboardEvent>;
  o: Subject<KeyboardEvent>;
  p: Subject<KeyboardEvent>;
  q: Subject<KeyboardEvent>;
  r: Subject<KeyboardEvent>;
  s: Subject<KeyboardEvent>;
  t: Subject<KeyboardEvent>;
  u: Subject<KeyboardEvent>;
  v: Subject<KeyboardEvent>;
  w: Subject<KeyboardEvent>;
  x: Subject<KeyboardEvent>;
  y: Subject<KeyboardEvent>;
  z: Subject<KeyboardEvent>;
}
interface IArrow {
  up: Subject<KeyboardEvent>;
  down: Subject<KeyboardEvent>;
  left: Subject<KeyboardEvent>;
  right: Subject<KeyboardEvent>;
}

interface INumpad {
  1: Subject<KeyboardEvent>;
  2: Subject<KeyboardEvent>;
  3: Subject<KeyboardEvent>;
  4: Subject<KeyboardEvent>;
  5: Subject<KeyboardEvent>;
  6: Subject<KeyboardEvent>;
  7: Subject<KeyboardEvent>;
  8: Subject<KeyboardEvent>;
  9: Subject<KeyboardEvent>;
  0: Subject<KeyboardEvent>;
  asterisk: Subject<KeyboardEvent>;
  forwardSlash: Subject<KeyboardEvent>;
  dash: Subject<KeyboardEvent>;
  plus: Subject<KeyboardEvent>;
  dot: Subject<KeyboardEvent>;
}

export function parseLocalDate(s) {
  var b = s.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2]);
}

export function convertHtmlToPlainText(html) {

  // Create a new div element
  var tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
}


export class DateUtils {
  public static defaultLanguage = 'en';
  public static getToday() {
    const today = new Date();
    const ymd = today.getFullYear().toString() + '-' +
      (today.getMonth() + 1).toString().padStart(2, '0') + '-' +
      today.getDate().toString().padStart(2, '0');
    return ymd;
  }

  public static getMaxDate() {
    const MAX_DATE = new Date();
    // Validate a maximum validation date for 100 years.
    MAX_DATE.setFullYear(new Date().getFullYear() + 100);
    return MAX_DATE.toISOString().substring(0, 10);
  };

  public static getMonthDays(dateValue?) {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;

    if (typeof dateValue == 'number' || typeof dateValue == 'string') {
      month = parseInt(dateValue.toString()) * 1;
    }

    date = new Date(year, month, 0);

    let monthDays = date.getDate();

    return Array.from({ length: monthDays }, (v, i) => i + 1);
  }

  public static toJsonDate(date) {
    return new Date(date).toISOString();
  }

  public static parseTime(t) {
    var d = new Date();
    var time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
    d.setMinutes(parseInt(time[2]) || 0);
    d.setSeconds(0);
    d.setMilliseconds(0);

    return d;
  }

  public static formatTime(t){
    return DateUtils.parseTime(t).toLocaleTimeString(DateUtils.defaultLanguage, { hour: '2-digit', minute:'2-digit'   });
  }
}
